.container {

    @apply w-full
        py-150
        space-y-300
        container-gutters-small;

    @container (min-width: 768px) {

        @apply container-gutters-medium;
    }
}

.hr {

    &.mobile {

        @apply block;

        @container (min-width: 768px) {

            display: none;
        }
    }
}

.subTitle {
    @apply md:text-600 md:leading-600 lg:text-700 lg:leading-700;
}

.orderHistory {

    @apply flex
        flex-col
        gap-y-150;
}

.needSomethingElse {

    @apply mb-150;
}

.dashboardGrid {

    @apply grid
        grid-cols-1
        gap-x-150
        gap-y-150
        mb-300;

    @container (min-width: 768px) {

        @apply grid-cols-2 mb-0;
    }

    @container (min-width: 1152px) {

        @apply grid-cols-3 mb-0;
    }

    .shortcutTile {

        @apply flex
            flex-row
            items-center
            justify-between
            bg-[theme('card.bordered.background')];

        padding: theme('card.padding.small');
        border: theme('card.bordered.border');
        border-radius: 12px;
    }
}

.callout {

    @apply mt-300;
}

.content {

    @apply flex
        flex-col
        justify-between
        gap-y-150;
}

.viewAllLink {

    @apply pt-050;
}
