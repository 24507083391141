.SelectorCard_card__ggqAM {
    position: relative;
    padding: 12px;
    border-radius: 12px;
    background-color: #FFFFFF;
    cursor: pointer;
}

    .SelectorCard_card__ggqAM:focus-visible {
        outline: none;
    }

    .SelectorCard_card__ggqAM:hover .SelectorCard_indicator__osZ3S, .SelectorCard_card__ggqAM:focus .SelectorCard_indicator__osZ3S, .SelectorCard_card__ggqAM:focus-within .SelectorCard_indicator__osZ3S {
            border-color: #007ACC;
        }

.SelectorCard_indicator__osZ3S {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: 1px solid #CAD8E2;
    transition: border-color 300ms cubic-bezier(0.23, 1, 0.32, 1);
    pointer-events: none;
}

.SelectorCard_indicator__osZ3S.SelectorCard_indicatorActive__K6vHu {
        border: 2px solid #007ACC;
    }

.JoiFormInputWrap_fieldValid__m4lem input, .JoiFormInputWrap_fieldValid__m4lem select {

        background: #FFFFFF;
        border: 1px solid #72EDC7;
        opacity: 1;
        fill: #303C69;
    }

.JoiFormInputWrap_fieldInvalid__9fWYh input, .JoiFormInputWrap_fieldInvalid__9fWYh select {

        background: #FFFFFF;
        border: 1px solid #DB0F64;
        opacity: 1;
        fill: #303C69;
    }

.TextInput_date__dO13p::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url('/images/icons/calendar-light.svg') no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        border-width: thin;
    }

.DateOfBirth_fieldSpacing____0N5 > * + * {
        margin-top: 4px;
    }

.DateOfBirth_field__CQUJz {
    width: 4.5rem;
}

.DateOfBirth_dob_question__JoHU_ {
    display: flex;
    gap: 4px;

}

.DateOfBirth_dob_question__JoHU_ input {
        -moz-appearance: textfield;
    }

.DateOfBirth_dob_question__JoHU_ input::-webkit-outer-spin-button,
        .DateOfBirth_dob_question__JoHU_ input::-webkit-inner-spin-button {
        -webkit-appearance: none;
                appearance: none;
            margin-right: 0;
}

.DateOfBirth_age__wYpX0 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.75rem;
    padding: 0 12px;
    margin-left: auto;
    background: #007ACC;
    border-radius: 6px;
    color: #FFFFFF;
}

.ProgressBar_progress_bar__19K7t {
    display: flex;
    width: 100%;
    height: 0.375rem;
    border-radius: 0.25rem;
    background-color: #D8D8D8;
}

.ProgressBar_progress_bar__indicator__xKB1X {
    border-radius: 0.25rem;
    transition-property: width, background;
    transition-duration: 0.3s;
    transition-timing-function: linear;
}

.ProgressBar_progress_bar__indicator__xKB1X.ProgressBar_progress_bar__indicator__default__Zcakn {
        background: linear-gradient(-45deg, rgb(22 188 229 / 1) 0%, rgb(20 150 225 / 1) 100%);
    }

.ProgressBar_background_color__error__H9KQw {
    background-color: #DB0F64;
}

.ProgressBar_background_color__warning__vxrKl {
    background-color: #FFDF8A;
}

.ProgressBar_background_color__success__GL9LZ {
    background-color: #72EDC7;
}

.ProgressBar_background_color__accent__iW29c {
    background-color: #72EDC7;
}
.CheckboxIcon_checkbox__Ty7w0 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 1px solid #CAD8E2;
}

.CheckboxIcon_checked__Lfuds {
    background-color: #303C69;
    border: #303C69;
}

.CheckboxIcon_disabled__qzdJT {
    border-color: #CAD8E2;
    background-color: #FFFFFF;
}

.CheckboxIcon_disabled__qzdJT.CheckboxIcon_checked__Lfuds {
        background-color: #CAD8E2;
    }

.CheckboxIcon_animated__wuzoY {
    transition-duration: 300ms;
}

.CheckboxIcon_instant__QIcYD {
    transition-duration: 0ms;
}

button.PriceFilter_option__rJisN {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent !important;
} /* Important need to override foundry css. */

    button.PriceFilter_option__rJisN:hover {
        background-color: transparent !important;
    }

.FilterShowMore_button__bZJKY span {
        text-decoration: underline;
    }

    .FilterShowMore_button__bZJKY:hover span, .FilterShowMore_button__bZJKY:focus span {
            text-decoration: none;
        }

button.FilterRadioOption_option__6izR_ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent !important;
} /* Important need to override foundry css. */

    button.FilterRadioOption_option__6izR_:hover {
        background-color: transparent !important;
    }

.OptionBlock_block__jAV3b {
    position: relative;
    display: block;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #CAD8E2;
    background-color: #FFFFFF;
    cursor: pointer;
}

    .OptionBlock_block__jAV3b:focus-visible {
        outline: none;
    }

    .OptionBlock_block__jAV3b:focus .OptionBlock_indicator___Smqu, .OptionBlock_block__jAV3b:hover .OptionBlock_indicator___Smqu {
            border-color: #007ACC;
        }

.OptionBlock_indicator___Smqu {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 12px;
    pointer-events: none;
}

.OptionBlock_indicatorChecked__e1pLP .OptionBlock_indicator___Smqu {
        border: 2px solid #1599E0;
        box-shadow: 0px 0px 0px 2px rgba(23, 167, 196, 0.10);
    }

.OptionBlock_indicatorInvalid__tTyp_ .OptionBlock_indicator___Smqu {
        border-color: #DB0F64;
        box-shadow: none;
    }

.OptionBlock_indicatorDisabled__s1h5V {
    cursor: not-allowed;
}

.OptionBlock_indicatorDisabled__s1h5V .OptionBlock_indicator___Smqu {
        border-color: #CAD8E2;
        box-shadow: none;
    }

.OptionBlock_indicatorDisabled__s1h5V > * {
        pointer-events: none;
    }

.RadioOption_option__MMfmQ {

    font-family: sofia-pro, arial, sans-serif;

    font-weight: 700;

    font-size: 14.222px;

    line-height: 150%;

    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.RadioOption_optionDisabled__itrZV {
    cursor: not-allowed;
    color: #5A5E6E;
}

.Checkbox_checkbox__BtAgD {

    font-family: fields, arial, system-ui, serif;

    font-weight: 600;

    font-size: 14.222px;

    line-height: 150%;

    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.Checkbox_checkboxDisabled__T3pfH {
    cursor: not-allowed;
    color: #5A5E6E;
}

.FilterActions_filterActions__UBi6c {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    justify-content: space-between;
    padding: 16px;
    background-color: #FFFFFF;
    border-top: 1px solid #CAD8E2;
}

.TreatmentFilter_filters___nXz4 {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 24px 16px;
    overflow: auto;
}

    .TreatmentFilter_filters___nXz4 > section {
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid #CAD8E2;
    }

    .TreatmentFilter_filters___nXz4 > section:last-child {
        margin: 0;
        padding: 0;
        border-bottom: none;
    }

.TreatmentFilter_button__VDdSn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    --button-bordered-default-background: #FFFFFF;
}

.TreatmentFilter_button__VDdSn:hover,
    .TreatmentFilter_button__VDdSn:focus {
        --button-bordered-hover-background: #FFFFFF;
    }

.TreatmentFilter_button__VDdSn {

    background-color: #FFFFFF;
}

.TreatmentFilter_filterCount__gOh9l {
    position: absolute;
    top: -0.625rem;
    right: -0.175rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.375rem;
    padding-top: 0.1875rem;
    padding-bottom: 0.3125rem;
    background-color: #007ACC;
    border-radius: 9999px;
    height: 18px;
    width: 18px;
}

.Attention_attention__A1HOV {
    padding: 24px 16px;
    border-radius: 12px;
}

    .Attention_attention__A1HOV > * + * {
        margin-top: 8px;
    }

.Attention_info__QnBYZ {
    border: 2px solid #007ACC;
}

.Attention_info__QnBYZ svg {
        fill: #007ACC;
    }

.Attention_success__EmyX7 {
    border: 2px solid #72EDC7;
}

.Attention_success__EmyX7 svg {
        fill: #72EDC7;
    }

.Attention_warning__E7rYj {
    border: 2px solid #FFDF8A;
}

.Attention_warning__E7rYj svg {
        fill: #FFDF8A;
    }

.Attention_error__bEL5M {
    border: 2px solid #DB0F64;
}

.Attention_error__bEL5M svg {
        fill: #DB0F64;
    }

.PriceComparison_listing__AsqH3 > * + * {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #EAF5FB;
    }

.SupportFormStyles_FormFieldReadonly__U4Jpj textarea {
        background-color: #F8F8F8;
        cursor: not-allowed;
        opacity: 1;
    }

.AccountOrdersBillingDelivery_container__qSgUY {

padding-left: 16px;

padding-right: 16px;

width: 100%
}

.AccountOrdersBillingDelivery_container__qSgUY > :not([hidden]) ~ :not([hidden]) {

--tw-space-y-reverse: 0;

margin-top: calc(48px * calc(1 - var(--tw-space-y-reverse)));

margin-bottom: calc(48px * var(--tw-space-y-reverse))
}

.AccountOrdersBillingDelivery_container__qSgUY {

padding-top: 24px;

padding-bottom: 24px
}

    @container (min-width: 768px) {.AccountOrdersBillingDelivery_container__qSgUY {

padding-left: 24px;

padding-right: 24px
}
    }

.AccountOrdersBillingDelivery_hr__TWVoz.AccountOrdersBillingDelivery_mobile__YU6q7 {

display: block
}

@container (min-width: 768px) {

.AccountOrdersBillingDelivery_hr__TWVoz.AccountOrdersBillingDelivery_mobile__YU6q7 {

            display: none
    }
        }

@media (min-width: 768px) {

.AccountOrdersBillingDelivery_subTitle__JT2dD {

font-size: 28.833px;

line-height: 124%
}
}

@media (min-width: 1024px) {

.AccountOrdersBillingDelivery_subTitle__JT2dD {

font-size: 32.437px;

line-height: 120%
}
}

.AccountOrdersBillingDelivery_orderHistory__KTy1P {

display: flex;

flex-direction: column;

row-gap: 24px
}

.AccountOrdersBillingDelivery_needSomethingElse__zsUd9 {

margin-bottom: 24px
}

.AccountOrdersBillingDelivery_dashboardGrid__yVBYw {

margin-bottom: 48px;

display: grid;

grid-template-columns: repeat(1, minmax(0, 1fr));

-moz-column-gap: 24px;

     column-gap: 24px;

row-gap: 24px
}

@container (min-width: 768px) {

.AccountOrdersBillingDelivery_dashboardGrid__yVBYw {

margin-bottom: 0;

grid-template-columns: repeat(2, minmax(0, 1fr))
}
    }

@container (min-width: 1152px) {

.AccountOrdersBillingDelivery_dashboardGrid__yVBYw {

margin-bottom: 0;

grid-template-columns: repeat(3, minmax(0, 1fr))
}
    }

.AccountOrdersBillingDelivery_dashboardGrid__yVBYw .AccountOrdersBillingDelivery_shortcutTile__5rqlI {

display: flex;

flex-direction: row;

align-items: center;

justify-content: space-between;

background-color: #FFFFFF;

        padding: 12px;
        border: 1px solid #CAD8E2;
        border-radius: 12px
}

.AccountOrdersBillingDelivery_callout__Ec8sT {

margin-top: 48px
}

.AccountOrdersBillingDelivery_content__ZPUQJ {

display: flex;

flex-direction: column;

justify-content: space-between;

row-gap: 24px
}

.AccountOrdersBillingDelivery_viewAllLink__oybQ1 {

padding-top: 8px
}

.TreatmentSort_button__RpxZe {

    display: flex;

    align-items: center;

    justify-content: space-between;

    min-width: 10rem;

    --button-bordered-default-background: #FFFFFF;
}

    .TreatmentSort_button__RpxZe:hover,
    .TreatmentSort_button__RpxZe:focus {

        --button-bordered-hover-background: #FFFFFF;
    }

    .TreatmentSort_button__RpxZe {

    background-color: #FFFFFF;
}

.SortAndFilterNoResults_clearAll__PxO2a span {
        text-decoration: underline;
    }

    .SortAndFilterNoResults_clearAll__PxO2a:hover span {
            text-decoration: none;
        }

.ConditionPageTreatments_treatments__tR61i {

    grid-template-columns: repeat(2, minmax(0, 1fr));

    display: grid;
    gap: 12px
}

    @media (min-width: 768px) {.ConditionPageTreatments_treatments__tR61i {

        grid-template-columns: repeat(3, minmax(0, 1fr));

        gap: 16px
    }
    }

