.button {

    display: flex;

    align-items: center;

    justify-content: space-between;

    min-width: 10rem;

    --button-bordered-default-background: theme('colors.neutrals.white');

    &:hover,
    &:focus {

        --button-bordered-hover-background: theme('colors.neutrals.white');
    }

    background-color: theme('colors.neutrals.white');
}
