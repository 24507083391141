.filters {
    flex-grow: 1;
    flex-shrink: 1;
    padding: theme('spacing.150') theme('spacing.100');
    overflow: auto;

    > section {
        padding-bottom: theme('spacing.200');
        margin-bottom: theme('spacing.200');
        border-bottom: 1px solid theme('colors.decorators.subtle');
    }

    > section:last-child {
        margin: 0;
        padding: 0;
        border-bottom: none;
    }
}

.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: theme('spacing.075');
    --button-bordered-default-background: theme('colors.neutrals.white');

    &:hover,
    &:focus {
        --button-bordered-hover-background: theme('colors.neutrals.white');
    }

    background-color: theme('colors.neutrals.white');
}

.filterCount {
    position: absolute;
    top: -0.625rem;
    right: -0.175rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.375rem;
    padding-top: 0.1875rem;
    padding-bottom: 0.3125rem;
    background-color: theme('colors.actions.secondary');
    border-radius: 9999px;
    height: 18px;
    width: 18px;
}
